import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Essence Alexander.
        </BigTitle>
        <Subtitle>
          I'm creating beautiful UI web experiences for the next generation of consumer-facing companies.
        </Subtitle>
      </Hero>

      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Essence Alexander" />
          <AboutSub>The English language can not fully capture the depth and complexity of my thoughts.</AboutSub>
        </AboutHero>
        <AboutDesc>
          I'm a self taught software engineer with 7 years professional experience. I've worked on enterprise level
          applications. I have worked as a front end software engineer & as a full stack software engineer. I have
          experience building scalable single page application's. I create fluid, responsive applications & progressive
          web apps. I'm a coffee connoisseur, & an avid vinyl collector. I thrive on designing creative workspaces. My
          passion is to use an altruistic & entrepreneurial mindset, to help solve real world challenges. I'm
          comfortable throughout my stack, but my passion lies in the front end, at the intersection of code and design.
          I strive to create software that not only functions efficiently under the hood, but also provides intuitive,
          beautiful user experiences.
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say{' '}
            <a href="mailto:upperlefty@zoho.eu?Subject=Hey,%20Essence!&body=Just%20wanted%20to%20reach%20out%20and...">
              Hi
            </a>{' '}
            or find me on other platforms: <a href="https://decadenttragedydreamland.tumblr.com/">Tumblr</a> &{' '}
            <a href="https://www.instagram.com/upper_lefty/">Instagram</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy;&nbsp;2020 Essence Alexander{' '}
          <a href="https://github.com/UpperLEFTY"> Please check out my Github profile</a>{' '}
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
